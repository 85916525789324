import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AccountInfo, AuthError, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { FeatureFlagService } from './feature-flag.service';
import { UserService } from './user.service';
import { CacheService } from './cache.service';
const b2cUserCancellationCode: string = 'AADB2C90091';

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {
  public userAccount?: AccountInfo | null;
  private readonly _destroying$ = new Subject<void>();
  constructor(
    private cacheService: CacheService,
    private featureFlagService: FeatureFlagService,
    private msalBroadcastService: MsalBroadcastService,
    private msalService: MsalService,
    private userService: UserService,
    private router: Router
  ) {
  }

  initialize(): void {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$),
      ).subscribe(() => {
        let accountData = this.msalService.instance.getAllAccounts();
        if (accountData.length === 0) {
          return;
        }
        this.configureAccount(accountData);
        this.featureFlagService.init();
        this.cacheService.init();
      });
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE),
        takeUntil(this._destroying$)
      ).subscribe((result: EventMessage) => {
        if (result.error instanceof AuthError) {
          const errorMessage = result.error?.message ?? '';
          if (errorMessage.indexOf(b2cUserCancellationCode) > -1) {
            this.msalService.loginRedirect();
            return;
          }
          this.router.navigateByUrl('/login-failed');
        }
      });
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE),
        takeUntil(this._destroying$)
      ).subscribe(() => {
        this.signOut();
      });
  }

  private configureAccount(accounts: AccountInfo[]): void {
    const activeAccount = this.msalService.instance.getActiveAccount();
    if (!activeAccount && accounts.length > 0) {
      let accounts = this.msalService.instance.getAllAccounts();
      this.msalService.instance.setActiveAccount(accounts[0]);
    }
    this.userAccount = this.msalService.instance.getActiveAccount();
    if (this.userAccount) {
      this.userService.setLocalAccount(this.userAccount);
    }
  }

  signOut(): void {
    const account = this.msalService.instance.getActiveAccount();
    this.msalService.logoutRedirect({
      account: account
    });
  }

  canActivateMaintenance() {
    return this.featureFlagService.featureFlag$.pipe(
      takeUntil(this._destroying$),
      map(setting => {
        if (setting?.isMaintenanceMode) {
          return true;
        }
        return this.router.createUrlTree(['/']);
      }));
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }
}
