import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { PortalCustomerConfigurationService } from 'src/app/core/services/portal-customer-configuration.service';
import { UserService } from '../../core/services/user.service';
import { ReportId } from '../model/report-id.enum';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  constructor(
    private userService: UserService,
    private customerConfiguration: PortalCustomerConfigurationService,
    private router: Router,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return combineLatest([
      this.userService.userProfile$,
      this.customerConfiguration.customerConfiguration$,
    ]).pipe(
      filter(([u]) => u !== null),
      map(([user, customerConfiguration]) => {
        if (!user) {
          return false;
        }
        if (!user.companies || user.companies.length === 0) {
          this.router.navigateByUrl('/hot-port-news');
          return true;
        }
        if (user.isShippingCustomer) {
          return true;
        }
        if (user.isLogisticsCustomer) {
          if (customerConfiguration?.isPurchaseOrderVisible) {
            this.router.navigateByUrl('/purchase-orders');
          }
          else if (customerConfiguration?.isShipmentVisible) {
            this.router.navigateByUrl('/shipments');
          }
          else if (customerConfiguration?.isContractLogisticsReportEnabled) {
            this.router.navigateByUrl(`/report/${ReportId.ContractLogisticsReport}`);
          }
          else if (customerConfiguration?.isHotPortNewsVisible) {
            this.router.navigateByUrl('/hot-port-news');
          }
          return true;
        }
        this.router.navigateByUrl('/unauthorized');
        return false;
      })
    );
  }
}
